<template>
  <div class="setting_table">
    <el-table :data="tableDataCopy">
      <el-table-column
        prop="name1"
        label="上午上学"
        header-align="center"
        width="400"
      >
        <template slot-scope="scope">
          <template v-if="scope.$index == 0">
            <div class="morning">
              <span>准时</span>
              <i class="iconfont iconzhixiangjiantou"></i>
              <span>迟到</span>
            </div>
          </template>
          <template v-else>
            <div class="morning_two">
              <el-time-picker
                style="width: 100px"
                size="mini"
                v-model="scope.row.amInStart"
                format="HH:mm"
                value-format="HH:mm"
                @change="change"
                placeholder="请选择"
              >
              </el-time-picker>
              <el-time-picker
                style="width: 100px"
                size="mini"
                v-model="scope.row.amInEnd"
                format="HH:mm"
                :picker-options="{
                  selectableRange: scope.row.amInStart ? scope.row.amInStart + ':00 - 23:59:00' : '00:00:00 - 23:59:00'
                }"
                value-format="HH:mm"
                placeholder="请选择"
                @change="change"
              >
              </el-time-picker>
              <el-time-picker
                style="width: 100px"
                size="mini"
                v-model="scope.row.amInLate"
                format="HH:mm"
                :picker-options="{
                  selectableRange: scope.row.amInEnd ? scope.row.amInEnd  + ':00 - 23:59:00' : '00:00:00 - 23:59:00'
                }"
                value-format="HH:mm"
                placeholder="请选择"
                @change="change"
              >
              </el-time-picker>
            </div>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="上午放学"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <template v-if="scope.$index == 0">
            <div class="tr_two">
              <span>起止时间</span>
            </div>
          </template>
          <template v-else>
            <div class="morning_two">
              <el-time-picker
                style="width: 100px"
                format="HH:mm"
                size="mini"
                value-format="HH:mm"
                @change="change"
                v-model="scope.row.amOutStart"
                :picker-options="{
                  selectableRange: scope.row.amInLate ? scope.row.amInLate  + ':00 - 23:59:00' : '00:00:00 - 23:59:00'
                }"
                placeholder="请选择"
              >
              </el-time-picker>
              <el-time-picker
                style="width: 100px"
                format="HH:mm"
                size="mini"
                value-format="HH:mm"
                v-model="scope.row.amOutEnd"
                placeholder="请选择"
                :picker-options="{
                  selectableRange: scope.row.amOutStart ? scope.row.amOutStart + ':00 - 23:59:00' : '00:00:00 - 23:59:00'
                }"
                @change="change"
              >
              </el-time-picker>
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-table :data="tableDataCopy">
      <el-table-column
        prop="name1"
        label="下午上学"
        header-align="center"
        width="400"
      >
        <template slot-scope="scope">
          <template v-if="scope.$index == 0">
            <div class="morning">
              <span>准时</span>
              <i class="iconfont iconzhixiangjiantou"></i>
              <span>迟到</span>
            </div>
          </template>
          <template v-else>
            <div class="morning_two">
              <el-time-picker
                style="width: 100px"
                size="mini"
                v-model="scope.row.pmInStart"
                range-separator="~"
                format="HH:mm"
                value-format="HH:mm"
                @change="change"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="请选择"
              >
              </el-time-picker>
              <el-time-picker
                style="width: 100px"
                size="mini"
                v-model="scope.row.pmInEnd"
                format="HH:mm"
                value-format="HH:mm"
                :picker-options="{
                  selectableRange: scope.row.pmInStart ? scope.row.pmInStart + ':00 - 23:59:00' : '00:00:00 - 23:59:00'
                }"
                @change="change"
                placeholder="请选择"
              >
              </el-time-picker>
              <el-time-picker
                style="width: 100px"
                size="mini"
                v-model="scope.row.pmInLate"
                format="HH:mm"
                value-format="HH:mm"
                :picker-options="{
                  selectableRange: scope.row.pmInEnd ? scope.row.pmInEnd + ':00 - 23:59:00' : '00:00:00 - 23:59:00'
                }"
                @change="change"
                placeholder="请选择"
              >
              </el-time-picker>
            </div>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="下午放学"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <template v-if="scope.$index == 0">
            <div class="tr_two">
              <span>起止时间</span>
            </div>
          </template>
          <template v-else>
            <div class="morning_two">
              <el-time-picker
                style="width: 100px"
                format="HH:mm"
                size="mini"
                @change="change"
                value-format="HH:mm"
                v-model="scope.row.pmOutStart"
                placeholder="请选择"
                :picker-options="{
                  selectableRange: scope.row.pmInLate ? scope.row.pmInLate + ':00 - 23:59:00' : '00:00:00 - 23:59:00'
                }"
              >
              </el-time-picker>
              <el-time-picker
                style="width: 100px"
                format="HH:mm"
                size="mini"
                @change="change"
                value-format="HH:mm"
                v-model="scope.row.pmOutEnd"
                placeholder="请选择"
                :picker-options="{
                  selectableRange: scope.row.pmOutStart ? scope.row.pmOutStart + ':00 - 23:59:00' : '00:00:00 - 23:59:00'
                }"
              >
              </el-time-picker>
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableDataCopy: [],
    };
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return {};
      },
    },
  },
  mounted() {},
  created() {
    console.log(this.tableData,'this.tableData')
    this.tableDataCopy = JSON.parse(JSON.stringify(this.tableData));
  },
  methods: {
    change() {
      this.$emit("update:tableData", this.tableDataCopy);
    },
  },
  watch: {
    tableData(n) {
      this.tableDataCopy = JSON.parse(JSON.stringify(n));
    }
  },
};
</script>

<style lang="scss">
.setting_table {
  .morning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
    .iconzhixiangjiantou {
      color: $main-color;
    }
  }
  .tr_two {
    text-align: center;
  }
  table > tbody > tr:nth-child(1) {
    background: #f7f8fa;
  }
  .morning_two {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>