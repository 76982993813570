import request from './request'

/**
 * 获取所有考勤设置
 */

export const getAttendanceSetting = (schoolId) => {
  return request({
    url: `/awardcard/api/school-config/${schoolId}`,
    method: 'get'
  })
}

/**
 * 设置考勤开关
 */

export const setAttendanceSwitch = (data) => {
  return request({
    url: '/awardcard/api/school-config/studentCheckConfig',
    method: 'put',
    data,
  })
}


/**
* 设置考勤时间
*/

export const setAttendanceDate = (data) => {
  return request({
    url: '/awardcard/api/school-config/studentCheckTime',
    method: 'put',
    data,
  })
}


/**
* 设置教师考勤
*/

export const setTeacherCheckConfig = (data) => {
  return request({
    url: '/awardcard/api/school-config/teacherCheckConfig',
    method: 'put',
    data,
  })
}

/**
* 获取默认考勤数据
*/

export const getDefaultConfig = (params) => {
  return request({
    url: '/awardcard/api/school-config/default',
    method: 'get',
    params
  })
}
