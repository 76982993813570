<template>
  <basic-block title="全校考勤统计" :isShowTitle="false" class="setting_main">
    <el-tabs v-model="activeName">
      <el-tab-pane label="考勤开关" name="first" class="scoll_tab">
        <div class="first_main">
          <div class="first_main_left">
            <div class="top">
              <div>
                <span>课堂考勤：</span>
                <el-switch
                  v-model="allSetting.enablePeriodCheckIn"
                  inactive-color="#f5f5f5"
                ></el-switch>
              </div>
              <div class="right">
                <span>出入校考勤：</span>
                <el-switch
                  v-model="allSetting.enableSchool"
                  inactive-color="#f5f5f5"
                  @change="enableSchoolChange"
                ></el-switch>
              </div>
              <div class="right">
                <span>默认出勤：</span>
                <el-switch
                  v-model="allSetting.enableDefault"
                  inactive-color="#f5f5f5"
                  @change="enableDefaultChange"
                ></el-switch>
              </div>
            </div>
            <div class="checks" v-if="allSetting.enableSchool">
              <el-checkbox v-model="allSetting.enableAmIn"
                >上午上学</el-checkbox
              >
              <el-checkbox v-model="allSetting.enableAmOut"
                >上午放学</el-checkbox
              >
              <br />
              <el-checkbox v-model="allSetting.enablePmIn"
                >下午上学</el-checkbox
              >
              <el-checkbox v-model="allSetting.enablePmOut"
                >下午放学</el-checkbox
              >
            </div>
            <div>
              <i class="iconfont iconxinxi"></i
              >关闭考勤开关后，对应考勤的功能将不能使用，考勤历史记录仍可以查看、考勤设置可以变更
            </div>
          </div>
          <div class="first_main_right">
            <img
              src="@/assets/images/attendance.png"
              alt
              width="214px"
              height="160px"
            />
          </div>
        </div>

        <div class="buttons">
          <!-- <el-button :size="$publicConfig.buttonSize" @click="$router.go(-1)">返回</el-button> -->
          <el-button
            :size="$publicConfig.buttonSize"
            type="primary"
            @click="setAttendanceSwitch"
            >保存</el-button
          >
        </div>
      </el-tab-pane>
      <el-tab-pane label="考勤时间" name="second" class="scoll_tab">
        <div class="two_main">
          <div class="left"></div>
          <div class="mid">
            <span class="label">考勤日期：</span>
            <div class="weeks">
              <el-checkbox-group v-model="allSetting.studentWeek">
                <el-checkbox
                  v-for="(v, k) in weeks"
                  :key="k"
                  :label="v.value"
                  >{{ v.label }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
            <span class="label">课堂考勤时间：</span>
            <div class="tips">
              <i class="iconfont iconxinxi"></i>
              <span class="tip"
                >每节课的考勤时间段默认为“基础信息设置-教学时间管理”添加的课节时间</span
              >
              <!-- <el-link type="primary" @click="gotoClick">点击跳转设置</el-link> -->
            </div>
            <span class="label">出入校考勤时间：</span>
            <el-radio-group
              v-model="currentSchool"
              :size="$publicConfig.radioSize"
              @input="schoolsTy"
            >
              <el-radio-button
                style="width: 120px"
                v-for="(v, k) in schools"
                :key="k"
                :label="v.campusCode"
                >{{ v.campusName }}</el-radio-button
              >
            </el-radio-group>
            <div class="attendance_time">
              <el-radio-group
                v-model="currentGradeId"
                :size="$publicConfig.radioSize"
              >
                <el-radio-button v-for="(v, k) in grades" :key="k" :label="k">{{
                  v
                }}</el-radio-button>
              </el-radio-group>
              <div class="batch" @click="dialog = true">
                <i class="iconfont iconpiliangcaozuo"></i> 批量设置
              </div>
            </div>
            <div style="height: 16px"></div>
            <setting-table
              :tableData.sync="currentTmpGradeCheckInConfigItem"

            ></setting-table>
          </div>
          <div class="right">
            <img
              src="@/assets/images/attendance.png"
              alt
              width="214px"
              height="160px"
            />
          </div>
        </div>
        <div class="buttons">
          <!-- <el-button :size="$publicConfig.buttonSize" @click="$router.go(-1)">返回</el-button> -->
          <el-button
            :size="$publicConfig.buttonSize"
            type="primary"
            @click="setAttendanceDate"
            >保存</el-button
          >
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="考勤通知" name="third">角色管理</el-tab-pane> -->
    </el-tabs>

    <el-dialog
      title="批量设置"
      :visible.sync="dialog"
      width="790px"
      @close="doalogClose"
    >
      <el-form label-width="70px">
        <el-form-item label="选择年级">
          <div class="grades">
            <el-checkbox-group v-model="curentGradesId">
              <el-checkbox v-for="(v, k) in grades" :key="k" :label="k">{{
                v
              }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>
        <el-form-item label="考勤时间">
          <setting-table
            :tableData.sync="currentTmpGradesCheckInConfigItem"
          ></setting-table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="doalogClose">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </div>
    </el-dialog>
  </basic-block>
</template>

<script>
import {
  getAttendanceSetting,
  setAttendanceDate,
  setAttendanceSwitch,
  getDefaultConfig
} from '@/http/setting'
import SettingTable from '@/views/attendance/setting/components/settingTable'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      activeName: 'first',
      weeks: [
        { label: '周一', value: '0' },
        { label: '周二', value: '1' },
        { label: '周三', value: '2' },
        { label: '周四', value: '3' },
        { label: '周五', value: '4' },
        { label: '周六', value: '5' },
        { label: '周日', value: '6' }
      ],
      dialog: false,
      //存储临时时间段配置变量
      tmpGradeCheckInConfigItems: {},
      //学校考勤配置
      allSetting: {
        studentWeek: []
      },
      currentGradeId: '',
      //当前批量选择的年级id
      curentGradesId: [],
      //当前批量设置的临时数组
      currentTmpGradesCheckInConfigItem: [{}, {}],
      currentSchool: 0,
      schools : [],
      gradess : [],
      tableData : []
    }
  },
  components: {
    SettingTable
  },
  created() {},
  computed: {
    ...mapGetters(['userInfo', 'yearsInfo', 'token']),
    // schools() {
    //   console.log(this.yearsInfo)
    //   return this.yearsInfo
    // },
    grades() {
      let params = {}
      this.gradess.forEach((item) => {
        params[item.gradeCode + ''] = item.grade
      })
      return params
    },
    currentTmpGradeCheckInConfigItem: {
      get() {
        if (this.currentGradeId) {
          let objs = {}
          for(const key in this.tmpGradeCheckInConfigItems){
            if(key == this.currentGradeId){
              objs = this.tmpGradeCheckInConfigItems[key]
              
            }
          }
          return [{}, objs]
        } else {
          return [{}, {}]
        }
      },
      set(n) {
        this.tmpGradeCheckInConfigItems[this.currentGradeId] = {
          ...this.tmpGradeCheckInConfigItems[this.currentGradeId],
          ...n[1]
        }
      }
    }
  },
  mounted() {
   
    this.inits()
    this.init()
  },
  methods: {
    inits(){
      this.schools = this.yearsInfo;
      this.currentSchool = this.yearsInfo[0].campusCode
      this.gradess = this.yearsInfo[0].gradeList
    },
    schoolsTy(e) {
      this.schools.forEach(item=>{
        if(e == item.campusCode){
          this.gradess = item.gradeList
        }
      })
      this.init()
    },
    gotoClick() {
      window.parent.location.href =
        window.parent.location.href.split('#/')[0] +
        '#/basicInfoManagement/teach-management'
    },
    doalogClose() {
      this.dialog = false
      this.currentTmpGradesCheckInConfigItem = [{}, {}]
    },
    enableSchoolChange(e) {
      if (!e) {
        this.allSetting.enableAmIn = false
        this.allSetting.enableAmOut = false
        this.allSetting.enablePmIn = false
        this.allSetting.enablePmOut = false
      }
    },
    enableDefaultChange(e){
      console.log(e)
      this.allSetting.enableDefault = e
      console.log(this.allSetting.enableDefault,'1')
    },
    async init() {
      let config = await getDefaultConfig()
      this.tableData = [];
      this.tmpGradeCheckInConfigItems = []
      getAttendanceSetting(this.userInfo.tnId).then((res) => {
        if (res.retCode == 1) {
          let { data } = res
          data.studentWeek = data.studentWeek
            .replace(/^,+/, '')
            .replace(/,+$/, '')
          data.studentWeek = data.studentWeek ? data.studentWeek.split(',') : []
          //循环构造临时时间段存储变量
          for (const key in this.grades) {
            let gradeCheckInConfigItem = data.gradeCheckInConfigItems.find(
              (item) => item.gradeCode == key
            )
            if (gradeCheckInConfigItem) {
              this.tableData.push(gradeCheckInConfigItem)
              this.tmpGradeCheckInConfigItems[key] = gradeCheckInConfigItem
            } else {
              this.tmpGradeCheckInConfigItems[key] = {
                amInEnd: config.data.amInEnd,
                amInLate: config.data.amInLate,
                amOutEnd: config.data.amOutEnd,
                amInStart: config.data.amInStart,
                amOutStart: config.data.amOutStart,
                pmInEnd: config.data.pmInEnd,
                pmInLate: config.data.pmInLate,
                pmInStart: config.data.pmInStart,
                pmOutEnd: config.data.pmOutEnd,
                pmOutStart: config.data.pmOutStart,
                schoolId: this.userInfo.tnId,
                gradeId: key
              }
            }
          }
          this.allSetting = data
          console.log(data,'data')
          setTimeout(() => {
            this.currentGradeId = this.gradess[0].gradeCode
          }, 500)
        }
      })
    },
    setAttendanceDate() {
      // this.dialog = true;
      let data = JSON.parse(JSON.stringify(this.allSetting))
      data.studentWeek = data.studentWeek.join(',')
      let items = []
      for (const key in this.tmpGradeCheckInConfigItems) {
        items.push(this.tmpGradeCheckInConfigItems[key])
      }
      data.gradeCheckInConfigItems = items
      setAttendanceDate(data).then((res) => {
        if (res.retCode == 1) {
          this.$message.success('考勤时间保存成功')
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },
    setAttendanceSwitch() {
      if (this.allSetting.enableSchool) {
        if (
          !this.allSetting.enableAmIn &&
          !this.allSetting.enableAmOut &&
          !this.allSetting.enablePmIn &&
          !this.allSetting.enablePmOut
        ) {
          this.$message.error('请勾选出入校考勤项目')
          return
        }
      }
      setAttendanceSwitch(this.allSetting).then((res) => {
        if (res.retCode == 1) {
          this.$message.success('考勤开关保存成功')
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },
    determine() {
      this.dialog = false;
      let index = JSON.parse(JSON.stringify(this.currentGradeId))
      this.currentGradeId = -1
      this.curentGradesId.forEach((itme) => {
        this.tmpGradeCheckInConfigItems[itme] =
          { ...this.tmpGradeCheckInConfigItems[itme], ...this.currentTmpGradesCheckInConfigItem[1] };
      });
      this.currentTmpGradesCheckInConfigItem = [{}, {}]
      this.curentGradesId = []
      this.currentGradeId = index
      this.$forceUpdate()
    },
  }
}
</script>

<style lang="scss">
.setting_main {
  .main_content {
    margin-top: 0px !important;
  }
  .el-radio-button {
    width: 67px;
    .el-radio-button__inner {
      width: 100%;
    }
  }
  .el-input__icon {
    line-height: 24px;
  }
  .scoll_tab {
    height: calc(100% - 104px);
    overflow-y: auto;
  }
  .buttons {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    background: #ffffff;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .weeks {
    width: 100%;
    height: 50px;
    background: #fafafc;
    border-radius: 2px;
    border: 1px solid #ebedf1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .grades {
    padding: 0 24px;
    width: 100%;
    background: #fafafc;
    border-radius: 2px;
    border: 1px solid #ebedf1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .two_main {
    display: flex;
    margin: 0 0 24px 0;
    .left {
      flex: 1;
    }

    .mid {
      flex: 0 1 640px;
      .label {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        margin: 24px 0 8px 0;
        display: inline-block;
      }

      .tips {
        width: 100%;
        height: 32px;
        background: #f5f6f8;
        border-radius: 2px;
        border: 1px solid #c1cad8;
        font-size: 14px;
        display: flex;
        align-items: center;
        .iconxinxi {
          margin: 0 12px;
          color: $main-color;
        }
        .tip {
          color: #999999;
        }
      }

      .attendance_time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-radio-group {
          width: calc(100% - 108px);
        }
        .batch {
          width: 106px;
          height: 32px;
          border: 1px solid #999999;
          color: #666666;
          line-height: 32px;
          text-align: center;
        }
        .batch:hover {
          cursor: pointer;
          border: 1px solid $main-color;
          color: $main-color;
        }
      }
    }
    .right {
      flex: 1;
      text-align: center;
      margin-top: 50px;
    }
  }
  .first_main {
    margin: 33px 16px;
    height: 204px;

    color: #999999;
    display: flex;
    .first_main_left {
      flex: 0 0 990px;
      background: #f7f8fa;
      padding: 24px 32px;
    }
    .first_main_right {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .checks {
      margin-bottom: 24px;
    }
    .iconxinxi {
      color: $main-color;
      margin-right: 20px;
    }
    .top {
      display: flex;
      span {
        font-size: 14px;
        color: #666666;
        display: block;
      }
      .el-switch {
        margin-top: 4px;
        margin-bottom: 24px;
      }
      .right {
        margin-left: 60px;
      }
    }
  }
  .el-tabs {
    height: 100%;
    .el-tabs__content {
      height: calc(100% - 40px);
    }
    .el-tabs__item {
      color: #999999;
    }
  }
}
</style>